import { createApp,ref } from 'vue'
import app from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router'


const appStorage = {
    user : ref({}),
    sysInfo: ref({})
};

createApp(app).
    use(Quasar, quasarUserOptions).
    use(router).
    provide('appStorage', appStorage).
    mount('#app')