import axios from 'axios';
import $router from '../router'

function apiRequest(method, url, data) {

    var config = {
        method: method,
        url: url,
        data: data,
    };

	//axios.defaults.baseURL = 'http://localhost:5020';
	//axios.defaults.withCredentials = true;
	
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';




    return axios
        .request(config)
        .then(response => {
            if (response.data.error) {
                //global error handling
            }

            return { data: response.data };
        })
        .catch(err => {

            if (err.isAxiosError) {

                //if (err.response.status === 401) {
                //    window.location = "/Admin/Login";
                //    return;
                //}

                if (err.response.data.code === 'ERR_AUTH') {


                    $router.push({ path: '/login' }).catch(() => {
                    });

                    //window.location = "/Login";
                    //return;
                }

                if (!err.response.data) {
                    return {
                        error: {
                            errorText: `${err.response.statusText}`
                        }
                    };

                }


                return { error: err.response.data };
            }




            return {
                error: {
                    errorText: `${err.message}`
                }
            }
        });
}


function apiLogin(data) {
    return apiRequest('POST', '/Auth/Login', data);
}

function apiLogout() {
    return apiRequest('POST', '/Auth/Logout');
}

function apiSysInfo() {
    return apiRequest('GET', '/Sys/Info');
}

function apiRoles() {
    return apiRequest('GET', '/Sys/Roles');
}

function apiCurrentUser() {
    return apiRequest('GET', '/Sys/CurrentUser');
}


function apiAllUsers(filter) {
    return apiRequest('POST', '/Users/All', filter);
}


function apiAddUser(user) {
    return apiRequest('POST', '/Users/Add', user);
}

function apiEditUser(id, user) {
    return apiRequest('PUT', '/Users/Edit/' + id, user);
}

function apiDeleteUser(id) {
    return apiRequest('DELETE', '/Users/DELETE/' + id);
}


function apiAllBanks() {
    return apiRequest('GET', '/Banks/All');
}




function apiAllBankProfiles(filter) {
    return apiRequest('POST', '/Banks/AllProfiles', filter);
}



function apiAddBankProfile(profile) {
    return apiRequest('POST', '/Banks/AddProfile', profile);
}

function apiReadBankProfile(id) {
    return apiRequest('GET', '/Banks/ReadProfile/' + id);
}

function apiEditProfile(id, body) {
    return apiRequest('PUT', '/Banks/EditProfile/' + id, body);
}




function apiReadProfileCerts(id) {
    return apiRequest('GET', '/Banks/ReadCerts/' + id);
}

function apiEditProfileCerts(id, body) {
    return apiRequest('POST', '/Banks/EditCerts/' + id, body);
}



export const apiOrders = {

    async all(filter) {
        return apiRequest('POST', '/Orders/All', filter);
    },

};



export const apiClients = {

    async all(filter) {
        return apiRequest('POST', '/Clients/All', filter);
    },

    async read(id) {
        return apiRequest('GET', '/Clients/Read/' + id);
    },

    async add(body) {
        return apiRequest('POST', '/Clients/Add', body);
    },

    async edit(id, body) {
        return apiRequest('PUT', '/Clients/Edit/' + id, body);
    },

    async delete(id) {
        return apiRequest('DELETE', '/Clients/Delete/' + id);
    },

    async generateToken(id,body) {
        return apiRequest('POST', '/Clients/GenerateToken/' + id, body);
    },

    async updateVersion(id, body) {
        return apiRequest('POST', '/Clients/UpdateVersion/' + id, body);
    },

    async bulkUpdateVersion(body) {
        return apiRequest('POST', '/Clients/BulkUpdateVersion', body);
    },

    async importClients(body) {
        return apiRequest('POST', '/Clients/Import/', body);
    },
};

export const apiMappings = {
    async all(clientId) {
        return apiRequest('GET', '/VariableQr/?clientId=' + clientId);
    },

    async createQr(clientId, body) {
        return apiRequest('POST', '/VariableQr/?clientId=' + clientId, body);
    },

    async editQr(id, body) {
        return apiRequest('PUT', '/VariableQr/' + id, body);
    },

    async payLoad(id) {
        return apiRequest('GET', '/VariableQr/' + id + '/PayLoad');
    },

    async deleteQr(id) {
        return apiRequest('DELETE', '/VariableQr/' + id);
    }
};

export const apiClientUpdates = {
    async all() {
        return apiRequest('GET', '/ClientUpdates/Updates');
    },

    async upload(file) {
        return apiRequest('POST', '/ClientUpdates/UploadFile', file);
    },

    async delete(file) {
        return apiRequest('DELETE', '/ClientUpdates/DeleteFile/' + file);
    },
};



export const apiAdmin = {

    async login(data) {
        return apiLogin(data);
    },

    async logout() {
        return apiLogout();
    }
};


export const apiSys = {

    async info() {
        return apiSysInfo();
    },

    async currentUser() {
        return apiCurrentUser();
    },


    async roles() {
        return apiRoles();
    }
};

export const apiUsers = {

    async all(filter) {
        return apiAllUsers(filter);
    },

    async add(user) {
        return apiAddUser(user);
    },


    async edit(id, user) {
        return apiEditUser(id, user);
    },

    async delete(id) {
        return apiDeleteUser(id);
    },

};


export const apiBanks = {

    async all() {
        return apiAllBanks();
    },


    async allProfiles(filter) {
        return apiAllBankProfiles(filter);
    },

    async readProfile(id) {
        return apiReadBankProfile(id);
    },


    async editProfile(id, body) {
        return apiEditProfile(id, body);
    },

    async addProfile(profile) {
        return apiAddBankProfile(profile);
    },

    async delete(id) {
        return apiRequest('DELETE', '/Banks/DELETE/' + id);
    },

    async logos() {
        return apiRequest('GET', '/Banks/Logos');
    },

    async logo(fileName) {
        return apiRequest('GET', '/Banks/Logos/'+fileName);
    }

};


export const apiCerts = {



    async profileCerts(id) {
        return apiReadProfileCerts(id);
    },

    async editCerts(id, body) {
        return apiEditProfileCerts(id, body);
    },
};
