<template>


    <q-layout view="lHh Lpr lFf">
        <q-page-container>
            <q-page class="
          auth-page
          window-height window-width
          row
          justify-center
          items-center
        ">

                <div class="big-loader flex flex-center">
                    <div>
                        <q-spinner-audio color="dark"
                            
                                   size="5.5em">
                        </q-spinner-audio>
                    </div>
                </div>


            </q-page>
        </q-page-container>
    </q-layout>









 
</template>

<script setup>

</script>

