import { createWebHashHistory, createRouter } from "vue-router";

const routes = [
    {
        path: '/',
        component: () => import("@/layouts/MainLayout.vue"),
        redirect: '/dashboard',
        children: [
            {
                path: '/dashboard',
                component: () => import("@/components/dashboard.vue")
            },
            {
                path: '/banks', component: () => import("@/components/banks.vue")
            },
            {
                path: '/users', component: () => import("@/components/users.vue")
            },
            {
                path: '/clients', component: () => import("@/components/clients.vue")
            },
            {
                path: '/orders', component: () => import("@/components/orders.vue")
            },
            {
                path: '/uploads', component: () => import("@/components/uploads.vue")
            }
        ],
    },

    {
        path: '/login',
        component: () => import("@/layouts/EmptyLayout.vue"),
        children: [
            {
                path: '',
                component: () => import("@/components/login.vue"),
            }
        ]
    },

    {
        path: '/:pathMatch(.)*',
        name: 'Error404',
        component: () => import("@/components/404.vue"),
        // meta: { layout: 'AppLayoutErrors' }
    },


];
const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;