<template>

    <BigLoader v-if="bigLoader"></BigLoader>

    <router-view v-else>
    </router-view>

</template>

<script setup>
    import { apiSys } from "./apiAxios/api.js";
    import { ref, onMounted, inject } from "vue";
    import BigLoader from '@/components/bigLoader'

    var bigLoader = ref(true);

    var storage = inject('appStorage');


    onMounted(async () => {

        const user = apiSys.currentUser();
        const info = apiSys.info();

        const [userResult, sysResult] = [await user, await info];

        storage.user = userResult.data;
        storage.sysInfo = sysResult.data;

        setTimeout(() => {
            bigLoader.value = false
        }, 1000)
    });




        //import { apiSys } from "./apiAxios/api.js";



        //onMounted(async () => {
        //    await apiSys.info();

        // /*   var res = await apiSys.currentUser();*/

        //    //if (res.error.code === 'ERR_AUTH') {
        //    //     window.location = "/Login";
        //    //        return;
        // /*   }*/
        //});

    /* import MainLayout from './components/MainLayout.vue'*/
        // import LoginLayout from './components/login.vue'



</script>
